import salesProfessionals from "../../../assetsC/images/sales_professionals.png";
import salesManagers from "../../../assetsC/images/sales_managers.png";
import resellersChannelPartners from "../../../assetsC/images/resellers_channel_partners.png";

export const solutionDropdown = [
	{
		img: salesProfessionals,
		text: "Sales Professionals",
		href: "/solutions/seller"
	},
	{
		img: salesManagers,
		text: "Sales Managers",
		href: "/solutions/manager"
	},
	{
		img: resellersChannelPartners,
		text: "Resellers & Channel Partners",
		href: "/solutions/reseller"
	}
];