import React from "react";

const SportBlog = () => {
	return (
		<div className="container blog-wrapper">
			<div className="about-heading"></div>
			<div className="blog-container">
				<div id="blog-header" style={{ paddingLeft: "20px" }}>
					<h4 className="blog-title">Sellr: Collaborate to identify and close more deals</h4>
					<h6 style={{ color: "white" }}>Written by Salman Ahmed, Jan 12, 2022</h6>
				</div>
				<div id="blog-content" className="blog-content">
					<p>
						As a career path, sales can be highly rewarding and can serve as a springboard for a wide variety of business careers,
						including executive management. This comes with a high degree of pressure to perform as there is no greater visibility,
						the results of your efforts are reflected directly on the company’s topline. Selling experience, however, is invaluable;
						understanding the sales motion, interacting with customers, delivering the company’s portfolio and value proposition and
						serving as the ‘front face’, are critical to gaining the perspective necessary to lead an organization.
					</p>
					<p>
						To be an effective seller, you have to be a stellar collaborator across the resources within your org and you need to
						continually adapt your efforts according to the requirements of your client and your org. One constant I’ve experienced
						in my enterprise sales career is that you're operating in an ever evolving environment; your territory, client list and
						solution portfolio can change significantly in as little as a quarter.

					</p>
					<p>
						The importance of learning from experienced colleagues can’t be understated. Seeking out opportunities to shadow other sellers or
						offering to assist with prospecting or proposal development became key to my own success. Soon enough that led to opportunities to
						accompany them on client meetings, enabling me to make meaningful contributions as I learned the mechanics of a deal.
					</p>

					<p>
						This is why we built Sellr, to help business development professionals connect, both internally and externally, with people who can
						help to win opportunities. As a seller, your reputation and rapport with those whom you work with, both colleagues and clients, is
						critical to your success as you will rely on both to discover opportunities to sell. In order to be an effective team player, you
						need to know your team and you need your team to know you. Find out who sells what and to which clients, discover who has key
						relationships with specific clients, ask for and give introductions, and automatically learn about specific deals your network has
						initiated, progressed, and won. Use Sellr to discover and expand your network as well as make yourself discoverable for new opportunities.
					</p>

					<p>
						As sellers, we are naturally competitive and are always looking for the win; leverage the power of your extended network to win more, together.
					</p>

				</div>
			</div>
			<div className="about-heading"></div>
		</div>
	);
};

export default SportBlog;