import React from "react";

const PrivacyPolicy = () => {
  return (
	<div className="container">
    <div className="privacy-container">
      <h4>Privacy Policy</h4>
      <h6>Last updated: Dec 1, 2021</h6>
      <p>
        This privacy policy ("policy") will help you understand how Sawal
        Technologies, Inc., owner of Sellr ("us", "we", "our") uses and protects
        the data you provide to us when you visit and use sellrapp.com
        ("website", "service"). This policy also explains how we as a data
        processor handle all customer relationship management information input
        into the Sellr software by the Account Owner or Authorized Users or
        generated on behalf of Account Owners in connection with the services.
        This Privacy Policy operates in connection with Sellr’s Terms of
        Service.
      </p>
      <p>
        We reserve the right to change this policy at any given time, of which
        you will be promptly updated. If you want to make sure that you are up
        to date with the latest changes, we advise you to frequently visit this
        page.
      </p>
      <h5>Sellr Software</h5>
      <p>
        Sellr provides a web browser extension and web application experience
        which works in conjunction with an enterprise CRM software instance. CRM
        data, including data pertaining to the user’s business and customers, is
        viewable by the user through the Sellr application. We do not store any
        data for longer than required to provide this service, and use it for no
        other purpose. Users of the application are the controllers of their
        data viewable through the application, the Sellr application is a
        non-controlling data processor.
      </p>
      <h5>What User Data We Collect</h5>
      <span>
        When you visit the website, we may collect the following data:
      </span>
      <ul style={{ paddingLeft: "40px" }}>
        <li>Your IP address.</li>
        <li>Your contact information and email address.</li>
        <li>Other information such as interests and preferences.</li>
        <li>Data profile regarding your online behavior on our website.</li>
      </ul>
      <span>Data profile regarding your online behavior on our website.</span>
      <ul style={{ paddingLeft: "40px" }}>
        <li>Customer information from your CRM instance</li>
        <li>Sales opportunity related information</li>
      </ul>
      <p>
        An individual may withdraw consent to process this information at any
        time to by contacting us at admin@sellrapp.com. We retain Customer and
        Business Data only for as long as necessary to fulfill the purposes of
        its collection and processing, including for the purposes of satisfying
        any legal, accounting, or reporting requirements.
      </p>
      <p>
        Sellr users own, and are data controllers of, Business and Customer Data
        they access through the Sellr application. Account Owners manage all
        Customer Data, as well as the users who create, manage, distribute, or
        report any Business Data generated by the Services.
      </p>
      <h5>Why We Collect Your Data</h5>
      <span>We are collecting your data for several reasons:</span>
      <ul style={{ paddingLeft: "40px" }}>
        <li>To better understand your needs.</li>
        <li>To improve our services and products.</li>
        <li>
          To send you promotional emails containing the information we think you
          will find interesting.
        </li>
        <li>
          To contact you to fill out surveys and participate in other types of
          market research.
        </li>
        <li>
          To customize our website according to your online behavior and
          personal preferences.
        </li>
      </ul>
      <h5>Safeguarding and Securing the Data</h5>
      <p>
        We are committed to securing your data and keeping it confidential. We
        have done all in our power to prevent data theft, unauthorized access,
        and disclosure by implementing the latest technologies and software,
        which help us safeguard all the information we collect online.
      </p>
      <p>
        Sellr treats all Customer and Business Data as confidential. All Data is
        safeguarded using industry- standard security practices to prevent
        unlawful disclosure. Sellr does not sell or make available any Customer
        or Business Data.
      </p>
      <h5>Our Cookie Policy</h5>
      <p>
        Once you agree to allow our website to use cookies, you also agree to
        use the data it collects regarding your online behavior (analyze web
        traffic, web pages you spend the most time on, and websites you visit).
      </p>
      <p>
        The data we collect by using cookies is used to customize our website to
        your needs. After we use the data for statistical analysis, the data is
        completely removed from our systems.
      </p>
      <p>
        Please note that cookies don't allow us to gain control of your computer
        in any way. They are strictly used to monitor which pages you find
        useful and which you do not so that we can provide a better experience
        for you.
      </p>
      <p>
        If you want to disable cookies, you can do it by accessing the settings
        of your internet browser. (Provide links for cookie settings for major
        internet browsers).
      </p>
      <h5>Links to Other Websites</h5>
      <p>
        Our website may contain links that lead to other websites. If you click
        on these links, we are not held responsible for your data and privacy
        protection. Visiting those websites is not governed by this privacy
        policy agreement. Make sure to read the privacy policy documentation of
        the website you go to from our website.
      </p>
      <h5>Restricting the Collection of your Personal Data</h5>
      <p>
        At some point, you might wish to restrict the use and collection of your
        personal data. You can achieve this by doing the following:
      </p>
      <p>
        When you are filling the forms on the website, make sure to check if
        there is a box which you can leave unchecked, if you don't want to
        disclose your personal information.
      </p>
      <p>
        If you have already agreed to share your information with us, feel free
        to contact us via email at admin@sellrapp.com and we will be more than
        happy to change this for you.
      </p>
      <p>
        Sellr will not lease, sell or distribute your personal information to
        any third parties, unless we have your permission. We might do so if the
        law forces us. Your personal information will be used when we need to
        send you promotional materials if you agree to this privacy policy.
      </p>
    </div>
	</div>
  );
};

export default PrivacyPolicy;
