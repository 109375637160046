import React from "react";
import Step1 from "../../../assetsC/images/step1-image.svg";
import Step2 from "../../../assetsC/images/step2-image.svg";
import Step3 from "../../../assetsC/images/step3-image.svg";

const AboutWork = () => {
  return (
    <div className="how-it-works">
      <div className="container .fade.visible">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="company-description">
              <p className="desc-1">360˚ view of account activity</p>
              <p className="desc-2">Cross-sell and Upsell</p>
              <p className="desc-3">
			    Instant recommendations are generated for you based on activity at your current accounts.
              </p>
            </div>
            <div className="project-image-holder">
              <img
                src={Step1}
                className="img-fluid rounded mx-auto d-block"
                alt="Project"
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="company-description">
              <p className="desc-1">know your team</p>
              <p className="desc-2">Automated Account Mapping</p>
              <p className="desc-3">
			    Sellr ingests all coverage data, quickly connecting you with the individuals you should collaborate with.
              </p>
            </div>

            <div className="project-image-holder">
              <img
                src={Step2}
                alt="Project"
                className="img-fluid rounded mx-auto d-block"
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="company-description">
              <p className="desc-1">inbound opportunities</p>
              <p className="desc-2">Share Your Expertise</p>
              <p className="desc-3">
			    Be discoverable by others in your organization based on your account coverage and expertise.
              </p>
            </div>

            <div className="project-image-holder">
              <img
                src={Step3}
                alt="Project"
                className="img-fluid rounded mx-auto d-block"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutWork;
