import * as types from "../Types/auth.types";

const initState = {
  loading: false,
  register: false,
  status: "",
};
const authReducer = (state = initState, action) => {
  switch (action.type) {
    case types.REGISTER_EMAIL_SUCCESS:
      state = {
        ...state,
        status: action.payload,
        register: true,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default authReducer;
