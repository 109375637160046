import amplitude from "amplitude-js";

// Use the key based on environment
const API_KEY =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_AMPLITUDE_STAGING_API_KEY
    : process.env.REACT_APP_AMPLITUDE_PRODUCTION_API_KEY;

console.log("====>amplitude event");

// connect amplitude
amplitude.getInstance().init(API_KEY, null, {
  platform: "Landing Page",
});

export { amplitude };
