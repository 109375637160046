import React, { Component } from "react";
import { amplitude } from "../../helper/amplitude";
import { Header, Footer } from "../layouts/home/index";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

class Layout extends Component {
  componentDidMount() {
    // amplitude event
    amplitude.getInstance().logEvent("Landing Page View");
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        <ToastContainer autoClose={2000} position="top-center" />
        <Header />
        {children}
        <Footer />
      </div>
    );
  }
}

export default Layout;
