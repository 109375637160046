import React from "react";
import { useHistory } from "react-router-dom";
import check from "../../../assetsC/images/check.png";

const Pricing = () => {
	const history = useHistory();

	const openContactusPage = () => {
		history.push("/contact-us");
	};

	const openChromeWebStore = () => {
		window.open("https://chrome.google.com/webstore/detail/sellr/ngiegdhdpcbmcgdmgegdknhclapnbfhf?utm_source=chrome-ntp-icon", "_blank");
	};

	return (
		<div className="about-area">
			<div className="container">
				<div className="about-heading"></div>
				<div className="row justify-center">
					<p className="pricing-label">Pricing</p>
				</div>
				<div className="row justify-center pricing-content-wrapper">
					<p className="pricing-content">Start with Sellr for free.</p>
					<p className="pricing-content">No credit card necessary.</p>
				</div>
				<div>
					<div className="pricing-row">
						<div className="pricing-row-heading">Simple Pricing</div>
						<div className="pricing-row-heading">Professional</div>
						<div className="pricing-row-heading">Manager</div>
						<div className="pricing-row-heading">Partners</div>
					</div>
					<div className="pricing-empty-row"></div>
					<div className="pricing-row">
						<div className="text"><b>SELLER TOOLS</b></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
					<div className="pricing-row">
						<div className="text">SFDC Ingestion</div>
						<div><img src={check} alt="Checked" /></div>
						<div><img src={check} alt="Checked" /></div>
						<div><img src={check} alt="Checked" /></div>
					</div>
					<div className="pricing-row">
						<div className="text">Account Mapping</div>
						<div><img src={check} alt="Checked" /></div>
						<div><img src={check} alt="Checked" /></div>
						<div><img src={check} alt="Checked" /></div>
					</div>
					<div className="pricing-row">
						<div className="text">AI Driven Recommendations</div>
						<div><img src={check} alt="Checked" /></div>
						<div><img src={check} alt="Checked" /></div>
						<div><img src={check} alt="Checked" /></div>
					</div>
					<div className="pricing-row">
						<div className="text">Deal Awareness</div>
						<div><img src={check} alt="Checked" /></div>
						<div><img src={check} alt="Checked" /></div>
						<div><img src={check} alt="Checked" /></div>
					</div>
					<div className="pricing-empty-row"></div>
					<div className="pricing-row">
						<div className="text"><b>MANAGER INSIGHTS</b></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
					<div className="pricing-row">
						<div className="text">Manager View</div>
						<div></div>
						<div><img src={check} alt="Checked" /></div>
						<div><img src={check} alt="Checked" /></div>
					</div>
					<div className="pricing-row">
						<div className="text">Pipeline Tracking</div>
						<div></div>
						<div><img src={check} alt="Checked" /></div>
						<div><img src={check} alt="Checked" /></div>
					</div>
					<div className="pricing-empty-row"></div>
					<div className="pricing-row">
						<div className="text"><b>CROSS COMPANY</b></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
					<div className="pricing-row">
						<div className="text">Comparative Analytics</div>
						<div></div>
						<div></div>
						<div><img src={check} alt="Checked" /></div>
					</div>
					<div className="pricing-row">
						<div className="text">Document Sharing</div>
						<div></div>
						<div></div>
						<div><img src={check} alt="Checked" /></div>
					</div>
					<div className="pricing-row">
						<div className="text">In-app Communication</div>
						<div></div>
						<div></div>
						<div><img src={check} alt="Checked" /></div>
					</div>
					<div className="pricing-row">
						<div className="text">Intra-company pipeline</div>
						<div></div>
						<div></div>
						<div><img src={check} alt="Checked" /></div>
					</div>
					<div className="pricing-empty-row"></div>
					<div className="pricing-row">
						<div></div>
						<div>
							<button
								className="btn-primary-color my-4 my-sm-4"
								type="button"
								onClick={openChromeWebStore}
							>Add To Chrome</button>
						</div>
						<div>
							<button
								className="btn-primary-color my-4 my-sm-4"
								type="button"
								onClick={openChromeWebStore}
							>Add To Chrome</button>
						</div>
						<div>
							<button
								className="btn-primary-outline-color my-4 my-sm-4"
								type="button"
								onClick={openContactusPage}
							>Contact Us</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Pricing;