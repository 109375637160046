import React from "react";
import ManagerImg from "../../../assetsC/images/manager.png";

const Manager = () => {
	const openChromeWebStore = () => {
		window.open("https://chrome.google.com/webstore/detail/sellr/ngiegdhdpcbmcgdmgegdknhclapnbfhf?utm_source=chrome-ntp-icon", "_blank");
	};

	return (
		<div>
			<div className="about-area">
				<div className="container">
					<div className="row justify-center">
						<div className="about-heading"></div>
						<div className="about-footer-inner">
							<div className="row align-items-center">
								<div className="col-md-6 col-sm-12 d-flex justify-content-center">
									<img src={ManagerImg} alt="Account Plan" className="img-fluid" width="90%" />
								</div>
								<div className="col-md-6 about-description">
									<h1>Empower your sales team</h1>
									<h2>View real-time activity across your team’s account base. Get live updates on opportunties, strategize on complex deals and enable your team to collaborate with business partners</h2>
									<div className="form-field">
										<div className="aboutHomeDiv">
											<button
												className="btn-primary-color my-4 my-sm-4 HomeShowBtn"
												type="button"
												id="bodydownloadbutton"
												onClick={() => openChromeWebStore()}
											>
												Add To Chrome
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="how-it-works">
				<div className="container .fade.visible">
					<div className="row align-items-center justify-content-center">
						<div className="col-lg-4 col-md-4 col-sm-12">
							<div className="company-description">
								<p className="desc-1">360˚ view of account activity</p>
								<p className="desc-2">Save Seller Time</p>
								<p className="desc-3">Instant recommendations are generated for you based on activity at your current accounts.</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12">
							<div className="company-description">
								<p className="desc-1">know your team</p>
								<p className="desc-2">Reduce Cadence Calls</p>
								<p className="desc-3">Sellr ingests all coverage data, quickly connecting you with the individuals you should collaborate with. </p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12">
							<div className="company-description">
								<p className="desc-1">inbound opportunities</p>
								<p className="desc-2">Optimize Deal Progress</p>
								<p className="desc-3">Be discoverable by others in your organization based on your account coverage and expertise.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Manager;