import React, { useState } from "react";
import { amplitude } from "../../../helper/amplitude";
import ProjectImage from "../../../assetsC/images/image-header.svg";
import playIcon from "../../../assetsC/images/play-icon.png";
import sellrDemo from "../../../assetsC/video/sellr_demo.mp4";

const AboutHome = () => {
  const [showVideo, enableVideo] = useState(false);

  const openChromeWebStore = () => {
    // amplitude event
    amplitude.getInstance().logEvent("Add To Chrome Clicked");
    window.open(
      "https://chrome.google.com/webstore/detail/sellr/ngiegdhdpcbmcgdmgegdknhclapnbfhf?utm_source=chrome-ntp-icon",
      "_blank"
    );
  };

  const playVideo = () => {
    enableVideo(true);
  };

  const VideoPlayer = () => {
    return (
      <div className="demo-video-container">
        <video className="demo-video" controls autoPlay>
          <source src={sellrDemo} type="video/mp4" />
          <source src={sellrDemo} type="video/ogg" />
          Your browser does not support HTML video.
        </video>
      </div>
    );
  };

  const WatchDemo = () => {
    return (
      <div className="wd-wrapper">
        <div className="watch-demo-wrapper" onClick={playVideo}>
          <div className="play-pause-icon">
            <img src={playIcon} alt="Play Icon" />
          </div>
          <div className="watch-demo-label-wrapper">
            <p className="watch-demo-label">WATCH DEMO</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="about-area">
      <div className="container">
        <div className="row justify-center">
          <div className="about-heading"></div>
          <div className="about-footer-inner">
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-12 d-flex justify-content-center">
                <img
                  src={ProjectImage}
                  alt="Project"
                  className="img-fluid"
                  width="90%"
                />
              </div>

              <div className="col-md-6 about-description ">
                <h1>Real-time intelligence to enable deal collaboration</h1>

                <h2>
                  Sellr provides automated recommendations on relevant pipeline
                  activity, enabling sellers to cross-sell {"&"} upsell with
                  ease.
                </h2>

                <div className="form-field">
                  <div className="aboutHomeDiv">
                    <button
                      className="btn-primary-color my-4 my-sm-4 HomeShowBtn"
                      type="submit"
                      id="bodydownloadbutton"
                      onClick={() => openChromeWebStore()}
                    >
                      Add To Chrome
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="video-wrapper">
            {showVideo ? <VideoPlayer /> : <WatchDemo />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHome;
