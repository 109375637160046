import React, { Component } from "react";
import { amplitude } from "../../helper/amplitude";
import {
  AboutHome,
  AboutWork,
  AboutFooter,
  Download,
} from "../layouts/home/index";
import "react-toastify/dist/ReactToastify.css";

class Home extends Component {
  componentDidMount() {
    // amplitude event
    amplitude.getInstance().logEvent("Landing Page View");
  }

  render() {
    return (
      <div>
        <AboutHome />
        <AboutWork />
        <AboutFooter />
        <Download />
      </div>
    );
  }
}

export default Home;
