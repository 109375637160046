import React, { Component } from "react";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.inputField = React.createRef();
    this.toggleCase = this.toggleCase.bind(this);
    this.state = {
      uppercase: false,
    };
  }
  toggleCase() {
    const isupper = this.state.uppercase;
    const value = this.inputField.current.value;

    this.inputField.current.value = isupper
      ? value.toLowerCase()
      : value.toUpperCase();
    this.setState({ uppercase: !isupper });
  }

  render() {
    return (
      <div>
        <input type="text" ref={this.inputField} />
        <button type="button" onClick={this.toggleCase}>
          Click me
        </button>
      </div>
    );
  }
}

export default Contact;
