import React from "react";

const SecurityPolicy = () => {
  return (
	<div className="container">
    <div className="privacy-container">
      <h5> We Believe in Transparency!!</h5>
      <h6>Last updated: Dec 1, 2021</h6>
      <p>
      Data is at the core of our business and the services we provide and we understand you may have questions about how we use and protect data.
      If you would like to learn more about our practices, please send a request via email to admin@sellrapp.com
      </p>
      <br/>
      <p>
      <h5><center> Data, Privacy, and security are important to us.</center></h5>
      </p>
      <br/>
      <h5> Data Center & Application Security</h5>
      <p>
      SellrApp infrastructure is hosted within the secure confines of Amazon Cloud which has completed SAS70 Type II audits.
      Amazon provides physical security across its world wide data centers along with the world class network security measures to help protect against any sort of DDoS ( Distributed Denial of  Service) attacks, man-in-the-middle attacks, IP spoofing, packet sniffing and port scanning.
      
      SellrApp uses HTTPS (HTTP Secure) protocol.
      
      SellrApp uses strict identity and access management procedures to limit internal access to systems and data.
      
      We practice enabling 2-factor login, key rotation and password policy as part of an employee life cycle program. 
      </p>
      <h5>Data Security</h5>
      <p>
      We’re relentlessly updating our systems to protect your data. Logging is a critical component to Sellrapp infrastructure, and we’re monitoring the platform to identify any misuse or problems. Logging is used extensively for application troubleshooting and investigating issues. Logs are streamed in real­time and over secure channels to a centralized logging service.
      We are in the process of obtaining SOC 2 Type 2 compliance and will have automated continuous monitoring in place to ensure that we stay compliant.
      </p>

      <h5>Security Procedures</h5>
      <p>
      We apply technical, administrative and organizational security measures to protect the data we collect against accidental or unlawful destruction, loss, alteration, unauthorized disclosure or access, in particular  where the processing involves the transmission of data over a network, and against other unlawful forms of processing. Sellr in the process to obtain SOC2 Type 2 Audit. 
      </p>

     
    </div>
	</div>
  );
};

export default SecurityPolicy;
