import React from "react";

const StartingUp = () => {
	return (
		<div className="container blog-wrapper">
			<div className="about-heading"></div>
			<div className="blog-container">
				<div id="blog-header" style={{ paddingLeft: "20px" }}>
					<h4 className="blog-title">Starting Up</h4>
					<h6 style={{ color: "white" }}>Written by Salman Ahmed, Jan 12, 2022</h6>
				</div>
				<div id="blog-content" className="blog-content">

					<p>
						It’s been over a year since we came up with the concept for Sellr. As we near general availability for the extension, it seems a good time to reflect on the journey so far. Starting a company is hard…and I’m told it only gets harder when you gain traction. But the excitement, anticipation and the consistent reinforcement we’ve received regarding the Sellr concept have continued to motivate us. At the very least, starting our own company has been an unparalleled learning experience.
					</p>
					<p>
						It’s exciting. Owning a solution from ideation to implementation, and the ability to rapidly respond to user needs got us out of bed in the morning. I can say whole heartedly, the actual experience has lived up to these expectations and then some. As an intrapreneur in my previous large organization role I had access to a wide range of resources. We would identify product opportunities, build consensus amongst internal stakeholders, and get management approval.  Once approved, an entire team of developers, marketers, salespeople and others, would be assigned to make this vision a reality. It’s not easy, but ultimately you would get a product built and have the resources to invest in its continued growth.
					</p>
					<p>
						As an entrepreneur, your resources are what you have independently, so you have to improvise, and that improvisation leads to a line of uncertainty. When we initially scoped out the Sellr platform, it looked completely different from its current state. We added every bell and whistle we believed people would want to have in a professional development tool. Inevitably, we learned that building a solution in a silo is not optimal and would require inordinate development effort prior to validating it within the market. We needed to be lean and scrappy with limited resources at our disposal.
					</p>
					<p>
						We began having conversations with our target market in earnest, reaching out to a broad cross-section of sales professionals to get their feedback on what we were doing. Business school forced us to be academic with our approach, ensuring we had primary and secondary research informing our decision making. Input from experienced entrepreneurs and individuals within the VC/Angel community helped us refine our approach and value proposition. All of these discussions provided us with valuable insights, for example, users’ preference for conversational UIs when interacting with other individuals. The launch is just a few weeks away, and we plan to continue our level of commitment and quickly iterate with user input.
					</p>
					<p>
						On the startup rollercoaster, one will continuously experience ups and downs, ebbs and flows, but the excitement you began with must remain. You will inevitably come across naysayers to your idea, like one of our beta users who told us “I don’t need to collaborate to win.” You will also continuously find competitors or quasi-competitors in the market you’d never heard of before (every idea has been tried in some fashion at some point).
					</p>
					<p>
						We started Sellr to create a tool we would use ourselves to address a problem we’d encountered in our careers. We’ve come a long way since then, and the journey is truly just beginning. I believe as long as you are solving a real-world problem and you are focused on execution with a commitment to being flexible in approach, you have a shot at building something meaningful.
					</p>


				</div>
			</div>
			<div className="about-heading"></div>
		</div>
	);
};

export default StartingUp;