import React from "react";
import { Header } from "../layouts/home/index";
import { TitleSection } from "../layouts/about";

const About = () => {
  return (
    <>
      <Header />
      <TitleSection />
    </>
  );
};

export default About;
