import axios from "axios";
// import axios from "./node_modules/axios";

const Base_URL = `http://sellrapp-api1.herokuapp.com/api`;

export function callEmailRegister(email) {
  return axios.post(`${Base_URL}/persist_email`, {
    sellr_email: email,
  });
}
