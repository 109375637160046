import React from "react";
import Step1 from "../../../assetsC/images/aled.png";
import Step2 from "../../../assetsC/images/sarim.png";
import Step3 from "../../../assetsC/images/siddiqui.png";

const data = [
  {
    name: "Alec McCain",
    description: "Client Executive IBM",
    quote:
      " As an account executive, knowing all the sellers within my organization I need to work with and quickly understanding their expertise and when to bring them in on client conversations is critical to my success.",
    avatar: Step1,
  },
  {
    name: "Sarim Ali",
    description: "Territory Manager Omron",
    quote:
      " Sellr makes it easy to find all the sellers within my org, and at channel partners, who cover my accounts. Interlocking with my counterparts enables me to quickly gain account intel and collaborate to make prospecting easier.",
    avatar: Step2,
  },
  {
    name: "Ali Siddiqui",
    description: "Sr Mgr Deal Desk",
    quote:
      " In my role on the deal desk, I assist sellers get the internal resources they need to meet client requirements for live engagements. Sellr makes it easy for my counterparts on the sales teams to connect with me when needed and keeps me updated.",
    avatar: Step3,
  },
];

const AboutFooter = () => {
  return (
    <div className="about-footer ">
      <div className="container users-quote">
        <h1>What our users say about us</h1>
        <div className="project-snap-row">
          <div className="row align-stretch">
            {data.map((element, index) => {
              return (
                <div className="col-lg-4 col-md-4 col-sm-12" key={index}>
                  <div className="project-snap-block">
                    <div className="testimonial">
                      <div className="aboutFooterText">
                        <img
                          src={element.avatar}
                          className="avatar"
                          alt={""}
                        ></img>

                        <div className="aboutFooterTextP">
                          <p className="user-name">{element.name}</p>
                          <p className="user-title">{element.description}</p>
                        </div>
                      </div>
                      <p className="quote">{element.quote}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFooter;
