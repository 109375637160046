import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";

import routes from "./components/pages/index";
import Layout from "./components/pages/Layout";

import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Router>
      <ToastContainer autoClose={3000} position="top-center" />
      <Switch>
        <Layout>
          {routes.map((route, idx) => (
            <Route
              exact
              path={route.path}
              component={withRouter(route.component)}
              key={idx}
            ></Route>
          ))}
        </Layout>
      </Switch>
    </Router>
  );
}

export default App;
