import React from "react";
import { useHistory } from "react-router-dom";
import ResellerImg from "../../../assetsC/images/reseller.png";

const ReSeller = () => {
	const history = useHistory();

	const openContactusPage = () => {
		history.push("/contact-us");
	};

	return (
		<div>
			<div className="about-area">
				<div className="container">
					<div className="row justify-center">
						<div className="about-heading"></div>
						<div className="about-footer-inner">
							<div className="row align-items-center">
								<div className="col-md-6 col-sm-12 d-flex justify-content-center">
									<img src={ResellerImg} alt="Account Plan" className="img-fluid" width="90%" />
								</div>
								<div className="col-md-6 about-description">
									<h1>Leverage business partners</h1>
									<h2>Account mapping with qualified business partner orgs enable sellers to find and collaborate on joint opportunities</h2>
									<div className="form-field">
										<div className="aboutHomeDiv">
											<button
												className="btn-primary-color my-4 my-sm-4 HomeShowBtn"
												type="button"
												onClick={openContactusPage}
											>Contact Us</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="how-it-works">
				<div className="container .fade.visible">
					<div className="row align-items-center justify-content-center">
						<div className="col-lg-4 col-md-4 col-sm-12">
							<div className="company-description">
								<p className="desc-1">360˚ view of account activity</p>
								<p className="desc-2">Share knowledge across orgs</p>
								<p className="desc-3">Instant recommendations are generated for you based on activity at your current accounts.</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12">
							<div className="company-description">
								<p className="desc-1">know your team</p>
								<p className="desc-2">Cross-company collaboration</p>
								<p className="desc-3">Sellr ingests all coverage data, quickly connecting you with the individuals you should collaborate with. </p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12">
							<div className="company-description">
								<p className="desc-1">inbound opportunities</p>
								<p className="desc-2">Trade Deal Insights</p>
								<p className="desc-3">Be discoverable by others in your organization based on your account coverage and expertise.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReSeller;