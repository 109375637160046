import React, { useEffect } from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed'

const Form = () => {
  useEffect(() => {
    var js,
      q,
      d = document,
      gi = d.getElementById,
      ce = d.createElement,
      gt = d.getElementsByTagName,
      id = 'typef_form',
      b = 'https://embed.typeform.com/'
    if (!gi.call(d, id)) {
      js = ce.call(d, 'script')
      js.id = id
      js.src = b + 'embed.js'
      q = gt.call(d, 'script')[0]
      q.parentNode.insertBefore(js, q)
    }
  }, [])

  return (
    <ReactTypeformEmbed
      class="typeform-widget"
      url="https://form.typeform.com/to/gUrUMBq7?typeform-medium=embed-snippet"
      style={{ width: '100%', height: '500px', position: 'relative' }}
    />
  )
}

export default Form
