import React from "react";
import accountPlan from "../../../assetsC/images/account_plan.png";

const Seller = () => {
	const openChromeWebStore = () => {
		window.open("https://chrome.google.com/webstore/detail/sellr/ngiegdhdpcbmcgdmgegdknhclapnbfhf?utm_source=chrome-ntp-icon", "_blank");
	};

	return (
		<div>
			<div className="about-area">
				<div className="container">
					<div className="row justify-center">
						<div className="about-heading"></div>
						<div className="about-footer-inner">
							<div className="row align-items-center">
								<div className="col-md-6 col-sm-12 d-flex justify-content-center">
									<img src={accountPlan} alt="Account Plan" className="img-fluid" width="90%" />
								</div>
								<div className="col-md-6 about-description">
									<h1>Collaborate and close deals</h1>
									<h2>Get real-time account insights to help you cross-sell, upsell and win more opportunities</h2>
									<div className="form-field">
										<div className="aboutHomeDiv">
											<button
												className="btn-primary-color my-4 my-sm-4 HomeShowBtn"
												type="button"
												id="bodydownloadbutton"
												onClick={() => openChromeWebStore()}
											>
												Add To Chrome
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="how-it-works">
				<div className="container .fade.visible">
					<div className="row align-items-center justify-content-center">
						<div className="col-lg-4 col-md-4 col-sm-12">
							<div className="company-description">
								<p className="desc-1">360˚ view of account activity</p>
								<p className="desc-2">Full Account History</p>
								<p className="desc-3">Instant recommendations are generated for you based on activity at your current accounts.</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12">
							<div className="company-description">
								<p className="desc-1">know your team</p>
								<p className="desc-2">Pipeline data at your fingertips</p>
								<p className="desc-3">Sellr ingests all coverage data, quickly connecting you with the individuals you should collaborate with. </p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12">
							<div className="company-description">
								<p className="desc-1">inbound opportunities</p>
								<p className="desc-2">Inbound Opportunities</p>
								<p className="desc-3">Be discoverable by others in your organization based on your account coverage and expertise.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Seller;