import React from "react";
import { amplitude } from "../../../helper/amplitude";

const Download = () => {
  const openChromeWebStore = () => {
    // amplitude event
    amplitude.getInstance().logEvent("Add To Chrome Clicked");
    window.open(
      "https://chrome.google.com/webstore/detail/sellr/ngiegdhdpcbmcgdmgegdknhclapnbfhf?utm_source=chrome-ntp-icon",
      "_blank"
    );
  };

  return (
    <div className="download-section ">
      <div className="container">
        <div className="project-snap-row">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="download-block">
                <div className="row align-items-center justify-content-center downloadSec">
                  <div className="col-lg-7 col-md-8 col-sm-12">
                    <p className="downloadHead">Download the Sellr Extension</p>
                    <p className="downloadQuote">
                      Sellr syncs with Salesforce and fits into your existing
                      workstream. Activate your account in less than 2 minutes.
                    </p>
                  </div>

                  <div className="col-lg-5 col-md-4 col-sm-10 inputMob">
                    <form className="downloadForm">
                      <div className="col-lg-10">
                        <button
                          className="btn-teritiary-color downloadBtn"
                          id="footerdownloadbutton"
                          onClick={() => openChromeWebStore()}
                        >
                          Add To Chrome
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
