import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import TermsAndConditions from "../layouts/home/TermsAndConditions";
import PrivacyPolicy from "../layouts/home/PrivacyPolicy";
import SecuirtyPolicy from "../layouts/home/SecurityPolicy";
import Blog from "../layouts/home/Blog";
import StartingUp from "../layouts/home/blogs/StartingUp";
import SportBlog from "../layouts/home/blogs/SportBlog";
import Seller from "../layouts/home/Seller";
import Manager from "../layouts/home/Manager";
import ReSeller from "../layouts/home/ReSeller";
import Pricing from "../layouts/home/Pricing";
import ContactUs from "../layouts/home/ContactUs";

const routes = [
  { path: "/", component: Home },
  { path: "/about", component: About },
  { path: "/contact", component: Contact },
  { path: "/terms-and-condition", component: TermsAndConditions },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/security-policy", component: SecuirtyPolicy },
  { path: "/blog", component: Blog },
  { path: "/blogs/starting-up", component: StartingUp },
  { path: "/blogs/sport-blog", component: SportBlog },
  { path: "/solutions/seller", component: Seller },
  { path: "/solutions/manager", component: Manager },
  { path: "/solutions/reseller", component: ReSeller },
  { path: "/pricing", component: Pricing },
  { path: "/contact-us", component: ContactUs },
];

export default routes;
