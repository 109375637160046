import React, { useState, useEffect } from "react";
import { Navbar } from "react-bootstrap";
import { amplitude } from "../../../helper/amplitude";
import logo from "../../../assetsC/images/sellr-logo.svg";
import "./style.css";
import { solutionDropdown } from "./MenuDropdownOptions.js";
import MenuDropdown from "./MenuDropdown.js";

const Header = () => {
  const openChromeWebStore = () => {
    // amplitude event
    amplitude.getInstance().logEvent("Add To Chrome Clicked");
    window.open(
      "https://chrome.google.com/webstore/detail/sellr/ngiegdhdpcbmcgdmgegdknhclapnbfhf?utm_source=chrome-ntp-icon",
      "_blank"
    );
  };
  const [openMenu, setOpenMenu] = useState(false);
  const [isSidebarOpened, setSidebar] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
	if (width <= 768 && isSidebarOpened) {
		document.body.style.overflow = "hidden";
	} else {
		document.body.style.overflow = "auto";
	}
  }, [width, isSidebarOpened]);

  const activateSolutionDropdown = () => {
	setOpenMenu(true);
  };

  const hideSolutionDropdown = () => {
	setOpenMenu(false);
  };

  const path = window.location.pathname;
  let activeMenu;
  if (path.includes("solutions")) {
	activeMenu = "solutions";
  }
  if (path.includes("pricing")) {
	activeMenu = "pricing";
  }
  if (path.includes("blog")) {
	activeMenu = "blog";
  }

  return (
    <header className="main-nav" style={{ backgroundColor: "#141417" }}>
      <div className="container headerContainer">
        <div className="headerAbout" onMouseLeave={hideSolutionDropdown}>
          <Navbar className="navabrClass" expand="md" variant="dark">
            <div className="logo navbarLogoHead">
              <a href="/">
                <img src={logo} alt="logo" width="auto" height="auto" />
              </a>
            </div>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="custom-toggler"
			  onClick={() => setSidebar(!isSidebarOpened)}
            />
            <Navbar.Collapse id="basic-navbar-nav" className="basic-navbar-nav">
			  <div className="ml-auto navbar-wrapper">
              <ul className="navbar-nav ml-auto">
				<li className="menu-option">
					<div
						className="menu"
						onMouseOver={hideSolutionDropdown}
					><a href="/">Home</a></div>
				</li>
				<li className="menu-option">
					<div className={"solution-menu menu "+ ((openMenu?'active':'') || (activeMenu === "solutions"?'active':''))} onMouseOver={activateSolutionDropdown}>
						Solutions
						<div className={"solution-sub-options " + (openMenu?'active':'')}>
							{
								solutionDropdown.map(((option, index) => {
									return (
										<p key={index}><a href={option.href}>{option.text}</a></p>
									)
								}))
							}
						</div>
					</div>
				</li>
				<li className="menu-option">
					<div
						className={"menu "+ (activeMenu === "pricing"?'active':'')}
						onMouseOver={hideSolutionDropdown}
					><a href="/pricing">Pricing</a></div>
				</li>
				<li className="menu-option">
					<div
						className={"menu "+ (activeMenu === "blog"?'active':'')}
						onMouseOver={hideSolutionDropdown}
					><a href="/blog">Blog</a></div>
				</li>
                <li
                  className={"nav-item-invite-display"}
                  style={{ padding: "0px" }}
                >
                  <button
                    onClick={() => openChromeWebStore()}
                    id="headerdownloadbutton"
                    className="btn-secondary-color add-to-chrome-btn"
                    type="button"
                  >
                    Add To Chrome
                  </button>
                </li>
              </ul>
			  </div>
            </Navbar.Collapse>
          </Navbar>
		  <div className="dropdown-wrapper">
			{
				openMenu && (
					<MenuDropdown options={solutionDropdown} />
				)
			}
		  </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
