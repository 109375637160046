import React from "react";
import Logo from "../../../assetsC/images/sellr-logo.svg";

const Footer = () => {
  return (
    <div className="main-nav main-footer">
      <div className="container">
        <div className="nav-wrapper about">
          <div className="logo" style={{ marginLeft: "-12px" }}>
            <a href="/">
              <img src={Logo} alt="logo" width="auto" height="auto" />
            </a>
          </div>
          <div className="footer-menu">
            <a href="/blog">Blog</a>
            <a href="/terms-and-condition">Terms of Service</a>
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/security-policy">Security Policy</a>
            <a href="/contact-us">Contact Us</a>
          </div>

          <nav className="navbar">
            <div className="navbar-items">
              <div className="btn-standard" href="/">
                © 2022 Sellr Inc. All rights reserved
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Footer;
