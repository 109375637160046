import * as types from "../Types/auth.types";
import { takeLatest, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
// import {
//   takeLatest,
//   select,
//   call,
//   put,
// } from "./node_modules/redux-saga/effects";

import * as Services from "../Service/auth.service";

function* SagaRegisterEmail(action) {
  try {
    const email = action.payload;
    toast.success("Request received successfully");
    yield put({
      type: types.REGISTER_EMAIL_SUCCESS,
      payload: "Successfully stored",
    });
    yield call(Services.callEmailRegister(email));
  } catch (err) {}
}

export default function* authWatcher() {
  yield takeLatest(types.REGISTER_EMAIL_REQUEST, SagaRegisterEmail);
}
