import React from "react";
import { useHistory } from "react-router-dom";

const Blog = () => {
	const history = useHistory();

	const OpenBlog = (url) => history.push(url);

	return (
		<div id="container" className="container blog-wrapper">
			<div className="about-heading"></div>
			<div id="blogs-container" className="blogs-container">
				<ul className="blog-list">
					<li>
						<div className="card" onClick={() => OpenBlog("/blogs/starting-up")}>
							<article>
								<header>
									<font color="black" size="4" face="verdana">Starting Up</font>
								</header>
								<div>
									<p>Starting a company is hard but exhilarating. </p>
								</div>
								<p><b><font color="black" size="2">By Salman Ahmed</font></b></p>
							</article>
						</div>
					</li>
					<br></br>
					<li>
						<div className="card" onClick={() => OpenBlog("/blogs/sport-blog")}>
							<article>
								<header>
									<font color="black" size="4" face="verdana">Sellr: Collaborate to identify and close more deals</font>

								</header>
								<div>
									<p>To be an effective seller, you have to be a stellar collaborator.</p>
								</div>
								<p><b><font color="black" size="2">By Salman Ahmed</font></b></p>

							</article>
						</div>
					</li>

				</ul>
			</div>

		</div>
	);
};

export default Blog;