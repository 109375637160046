import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";

// import {
//   createStore,
//   applyMiddleware,
//   compose,
//   combineReducers,
// } from "./node_modules/redux";
// import createSagaMiddleware from "./node_modules/redux-saga";

import authWatcher from "./Saga/auth.saga";
import authReducer from "./Reducer/auth.reducer";
import { all, fork } from "redux-saga/effects";

// import { all, fork } from "./node_modules/redux-saga/effects";

export function* rootSaga() {
  yield all([fork(authWatcher)]);
}

const rootReducer = combineReducers({
  auth: authReducer,
});

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

export default store;
