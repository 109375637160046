import React, { Component } from "react";

class MenuDropdown extends Component {
	render() {
		const { options } = this.props;
		const path = window.location.pathname;
		return (
			<div className="menu-dropdown">
				{
					options.map(((option, index) => {
						return (
							<div className="dropdown-option" key={index}>
								<img src={option.img} alt="Sales Professionals" />
								<p className={"dropdown-text " + (path === option.href ? "active":"")}><a href={option.href}>{option.text}</a></p>
							</div>
						)
					}))
				}
			</div>
		);
	}
}

export default MenuDropdown;