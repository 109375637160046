import React from "react";

const ContactUs = () => {
	return (
		<div className="about-area">
			<div className="container">
				<div className="about-heading"></div>
				<iframe
					src="https://xdc1iycz5q7.typeform.com/to/VqEwgCLA"
					title="Contact Us"
					className="contact-us-iframe"
				></iframe>
			</div>
		</div>
	);
};

export default ContactUs;